import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { EVENT_CONTRACT } from '../../../../apollo/query/event';
import BadResponse from '../../../../components/BadResponse';
import {
    Row,
    Col,
    Form,
    Skeleton,
    Button,
    PageHeader,
    Card,
    Select,
    Input,
    Typography,
    InputNumber,
    Divider,
    Modal,
    Alert,
    Checkbox,
    Tooltip,
    message,
    Space,
    Descriptions,
} from 'antd';
import { useIntl } from 'react-intl';
import BreadcrumbBuilder from '../../../../components/Breadcrumb';
import { getAdminPath, pushNextOrFallback } from '../../../../util/router';
import BadResponseErrorAlert from '../../../../components/BadResponseErrorAlert';
import IntlMessages from '../../../../util/IntlMessages';
import { Link, useHistory } from 'react-router-dom';
import DevButton from '../../../../components/Dev/DevButton';
import { boolToInt, floatToInt, intToBool, intToFloat } from '../../../../util/number';
import { GENERATE_EVENT_CONTRACT, RESET_EVENT_CONTRACT, SIGN_EVENT_CONTRACT, UPDATE_EVENT_PAYMENTS } from '../../../../apollo/mutation/event';
import { defaultCatchException, defaultMutationCallback } from '../../../../apollo/callbacks';
import { InfoCircleOutlined, LoadingOutlined, RocketOutlined } from '@ant-design/icons';
import { formatDate } from '../../../../util/date';
import { ORGANIZATION_BANK_ACCOUNT_STATE } from '../../../../constants/App';
import { isSuperAdmin } from '../../../../util/user';
import { range } from 'lodash';
import { formatCurrency } from '../../../../util/string';

const BankPaymentProvider = ({ provider, organization, index }) => {

    const getBankAccountInfo = () => {

        const isBrazil = organization?.bank_account?.data?.location === 'brazil';

        if (isBrazil) {
            return {
                isBankAccountReviewed: Boolean(organization?.bank_account?.data?.pix),
                delayDefaultValue: 1 * 60 * 60 * 24,
                descriptionReference: <Descriptions.Item label="PIX">{organization?.bank_account?.data?.pix}</Descriptions.Item>
            }
        } else {
            return {
                isBankAccountReviewed: organization?.bank_account?.state === ORGANIZATION_BANK_ACCOUNT_STATE.REVIEW,
                delayDefaultValue: 6 * 60 * 60 * 24,
                descriptionReference: <Descriptions.Item label="IBAN">{organization?.bank_account?.data?.iban}</Descriptions.Item>
            }
        }
    }

    const bankAccountInfo = getBankAccountInfo();

    return (
        <div key={index} className="gx-pb-4 gx-px-4 gx-pt-4 gx-my-3 gx-bg-light-grey">
            <Form.Item name={['providers', index, 'slug']} noStyle>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item valuePropName="checked" name={['providers', index, 'active']} label={<IntlMessages id={`payments.${provider.slug}`} />}>
                <Checkbox disabled={!bankAccountInfo.isBankAccountReviewed}>
                    <IntlMessages id="common.active" />
                </Checkbox>
            </Form.Item>

            {bankAccountInfo.isBankAccountReviewed ? (
                <div>
                    <Form.Item
                        initialValue={bankAccountInfo.delayDefaultValue}
                        label={<IntlMessages id="common.delayed_competition_payment_expiration_time_help" />}
                        name={['providers', index, 'expiration_span']}
                    >
                        <Select size="small" style={{ maxWidth: 200 }}>
                            {range(0, 10).map((i) => (
                                <Select.Option key={i} value={i * 60 * 60 * 24}>
                                    {i} <IntlMessages id="common.days" />
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Descriptions
                        size="small"
                        column={1}
                        title={
                            <Link to={getAdminPath('organization/settings')}>
                                <IntlMessages id="common.bank_account" />
                            </Link>
                        }
                        bordered
                    >
                        <Descriptions.Item label={<IntlMessages id="common.bank_account_holder" />}>
                            {organization?.bank_account?.data?.bank_holder}
                        </Descriptions.Item>
                        {bankAccountInfo.descriptionReference}
                    </Descriptions>
                </div>
            ) : (
                <Alert
                    className="gx-mt-3"
                    type="warning"
                    showIcon
                    message={
                        <span>
                            <IntlMessages id="common.use_your_own_bank_account_info" />
                            &nbsp;
                            <Link to={getAdminPath('organization/settings')}>
                                <br />
                                <IntlMessages id="common.settings" />
                            </Link>
                        </span>
                    }
                />
            )}
        </div>
    );
};

const BoletoPaymentProvider = ({ provider, index }) => {
    return (
        <div key={index} className="gx-pb-4 gx-px-4 gx-pt-4 gx-my-3 gx-bg-light-grey">
            <Form.Item name={['providers', index, 'slug']} noStyle>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item valuePropName="checked" name={['providers', index, 'active']} label={<IntlMessages id={`payments.${provider.slug}`} />}>
                <Checkbox>
                    <IntlMessages id="common.active" />
                </Checkbox>
            </Form.Item>
            <Form.Item
                initialValue={6 * 60 * 60 * 24}
                label={<IntlMessages id="common.delayed_competition_payment_expiration_time_help" />}
                name={['providers', index, 'expiration_span']}
            >
                <Select size="small" style={{ maxWidth: 200 }}>
                    {range(0, 10).map((i) => (
                        <Select.Option key={i} value={i * 60 * 60 * 24}>
                            {i} <IntlMessages id="common.days" />
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    );
};

const CashPaymentProvider = ({ provider, index }) => {
    return (
        <div key={index} className="gx-pb-2 gx-px-4 gx-pt-4 gx-my-3 gx-bg-light-grey">
            <Alert
                className="gx-mt-3"
                type="info"
                showIcon
                message={
                    <span>
                        <IntlMessages id="payments.cash_tip" />
                    </span>
                }
            />
            <Form.Item name={['providers', index, 'slug']} noStyle>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item initialValue={true} valuePropName="checked" name={['providers', index, 'active']} label={<IntlMessages id={`payments.${provider.slug}`} />}>
                <Checkbox disabled={true}>
                    <IntlMessages id="common.active" />
                </Checkbox>
            </Form.Item>
        </div>
    );
}

const CommonPaymentProvider = ({ provider, index }) => {

    return (
        <div key={index} className="gx-pb-2 gx-px-4 gx-pt-4 gx-my-3 gx-bg-light-grey">
            <Form.Item name={['providers', index, 'slug']} noStyle>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item valuePropName="checked" name={['providers', index, 'active']} label={<IntlMessages id={`payments.${provider.slug}`} />}>
                <Checkbox>
                    <IntlMessages id="common.active" />
                </Checkbox>
            </Form.Item>
        </div>
    );
};

const PaymentProvider = ({ location, provider, index, organization }) => {

    switch (provider.slug) {
        case 'bank':
            return <BankPaymentProvider location={location} provider={provider} organization={organization} index={index} />;
        case 'boleto':
            return <BoletoPaymentProvider provider={provider} index={index} />;
        case 'cash':
            return <CashPaymentProvider provider={provider} index={index} />;
        default:
            return <CommonPaymentProvider provider={provider} index={index} />;
    }
};

const EventSubscriptionsForm = ({ currentOrganization, paymentLocations, event, intl, refetch, me }) => {
    const [form] = Form.useForm();

    const [generateContract, { loading: generateContractLoading }] = useMutation(GENERATE_EVENT_CONTRACT);
    const [resetContract, { loading: resetContractLoading }] = useMutation(RESET_EVENT_CONTRACT);
    const [signContract, { loading: signContractLoading }] = useMutation(SIGN_EVENT_CONTRACT);
    const [updatePayments, { loading: updatePaymentsLoading }] = useMutation(UPDATE_EVENT_PAYMENTS);

    const history = useHistory();

    const handleOk = (values) => {
        return updatePayments({
            variables: {
                id: event.id,
                input: {
                    fee_to_org: intToBool(values.fee_to_org),
                    ks_fee_fixed: floatToInt(values.ks_fee_fixed),
                    ks_fee_percent: floatToInt(values.ks_fee_percent),
                    sp_fee_to_org: intToBool(values.sp_fee_to_org),
                    ks_sp_fee_fixed: floatToInt(values.ks_sp_fee_fixed),
                    ks_sp_fee_percent: floatToInt(values.ks_sp_fee_percent),
                    providers: values.providers,
                },
            },
        })
            .then((response) =>
                defaultMutationCallback(response, () => {
                    message.success(intl.formatMessage({ id: 'common.edit_successful' }));
                    refetch();
                })
            )
            .catch((e) => defaultCatchException(e, intl));
    };

    const onGenerateContract = (force = false) => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.confirm' }),
            content: intl.formatMessage({ id: 'common.generate_contract_confirm' }),
            okText: intl.formatMessage({ id: 'common.yes' }),
            okButtonProps: {
                loading: generateContractLoading,
            },
            onOk: (close) => {
                form.validateFields()
                    .then((values) => {
                        handleOk(values).then(() => {
                            generateContract({
                                variables: {
                                    id: event.id,
                                    input: {
                                        force: force,
                                        signer_name: values.signer_name,
                                        organization_name: values.organization_name,
                                        organization_address: values.organization_address,
                                    },
                                },
                            })
                                .then((response) =>
                                    defaultMutationCallback(response, () => {
                                        close();
                                        refetch();
                                    })
                                )
                                .catch((e) => defaultCatchException(e, intl));
                        });
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                        close();
                    });
            },
        });
    };

    const closeHandler = () => {
        pushNextOrFallback(history, getAdminPath(`event/${event.id}/dashboard`));
    };

    const handleError = () => { };

    const { organization, payments, contract } = event;

    const canUpdateContractFields = !contract.generated || isSuperAdmin(me);

    return (
        <>
            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.subscriptions" />
                            </span>
                        }
                        onBack={() => history.goBack()}
                        extra={[
                            <Button key="1" onClick={() => closeHandler()}>
                                <IntlMessages id="common.cancel" />
                            </Button>,
                            contract.generated && !contract.signed ? (
                                <Button
                                    key="3"
                                    className="gx-btn gx-btn-warning"
                                    loading={false}
                                    onClick={() => {
                                        Modal.confirm({
                                            title: intl.formatMessage({ id: 'common.confirm' }),
                                            content: intl.formatMessage({ id: 'common.reset_contract_confirm' }),
                                            okText: resetContractLoading ? <LoadingOutlined /> : intl.formatMessage({ id: 'common.yes' }),
                                            onOk: (close) => {
                                                form.validateFields()
                                                    .then((values) => {
                                                        resetContract({
                                                            variables: {
                                                                id: event.id,
                                                            },
                                                        })
                                                            .then((response) =>
                                                                defaultMutationCallback(response, () => {
                                                                    close();
                                                                    refetch();
                                                                })
                                                            )
                                                            .catch((e) => defaultCatchException(e, intl));
                                                    })
                                                    .catch((info) => {
                                                        console.log('Validate Failed:', info);
                                                        close();
                                                    });
                                            },
                                        });
                                    }}
                                >
                                    {intl.formatMessage({ id: 'common.reset_contract' }).toUpperCase()}
                                </Button>
                            ) : null,
                            !contract.generated && !contract.signed ? (
                                <Button
                                    key="3"
                                    className="gx-btn gx-btn-secondary"
                                    loading={false}
                                    onClick={() => {
                                        onGenerateContract();
                                    }}
                                >
                                    {intl.formatMessage({ id: 'common.generate_contract' }).toUpperCase()}
                                </Button>
                            ) : null,
                            contract.signed ? (
                                <Button
                                    key="2"
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    type="primary"
                                    loading={updatePaymentsLoading}
                                >
                                    {intl.formatMessage({ id: 'common.save' }).toUpperCase()}
                                </Button>
                            ) : null,
                        ]}
                    />
                </Col>

                <Col xs={24}>
                    <BadResponseErrorAlert />
                </Col>

                {contract.generated && !contract.signed && (
                    <Col xs={24}>
                        <Alert
                            type="info"
                            showIcon
                            message={<IntlMessages id="common.download_contract" />}
                            description={
                                <Row>
                                    <Col xs={18} className="gx-pl-0">
                                        <IntlMessages id="common.contract_has_been_generated" />
                                    </Col>
                                    <Col xs={6} className="gx-text-right">
                                        <a className="ant-btn ant-btn-primary" target="_blank" href={event.contract.url} rel="noreferrer">
                                            <IntlMessages id="common.download_contract" />
                                        </a>
                                    </Col>
                                </Row>
                            }
                        />

                        <Alert
                            type="warning"
                            showIcon
                            message={<IntlMessages id="common.close_deal" />}
                            description={
                                <Row>
                                    <Col xs={24} className="gx-pl-0">
                                        <IntlMessages id="common.close_deal_help" />
                                    </Col>
                                    <Col xs={24} className="gx-pl-0 gx-pt-4">
                                        <Form
                                            layout="inline"
                                            onFinish={(values) => {
                                                signContract({
                                                    variables: {
                                                        id: event.id,
                                                    },
                                                })
                                                    .then((response) =>
                                                        defaultMutationCallback(response, () => {
                                                            refetch();
                                                        })
                                                    )
                                                    .catch((e) => defaultCatchException(e, intl));
                                            }}
                                        >
                                            <Form.Item
                                                name="confirm"
                                                valuePropName="checked"
                                                rules={[{ required: true, message: intl.formatMessage({ id: 'common.field_required' }) }]}
                                            >
                                                <Checkbox>
                                                    <IntlMessages id="common.accept_contract_conditions" />
                                                </Checkbox>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button loading={signContractLoading} htmlType="submit" className="ant-btn ant-btn-primary">
                                                    <IntlMessages id="common.accept" />
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            }
                        />
                    </Col>
                )}

                {contract.signed && (
                    <Col xs={24}>
                        <Alert
                            type="success"
                            showIcon
                            message={<IntlMessages id="common.download_contract" />}
                            description={
                                <Row>
                                    <Col xs={24} md={11} lg={14} className="gx-pl-0">
                                        <IntlMessages id="common.contract_accepted_at" values={{ date: formatDate(contract.signed_at) }} />
                                    </Col>
                                    <Col xs={24} md={13} lg={10} className="gx-text-right gx-mt-3 gx-mt-md-0">
                                        <Space>
                                            <a className="ant-btn ant-btn-primary" target="_blank" href={event.contract.url} rel="noreferrer">
                                                <IntlMessages id="common.download_contract" />
                                            </a>
                                            {isSuperAdmin(me) && (
                                                <Button icon={<RocketOutlined className="gx-pr-1" />} onClick={() => onGenerateContract(true)}>
                                                    <IntlMessages id="common.regenerate_contract" />
                                                </Button>
                                            )}
                                        </Space>
                                    </Col>
                                </Row>
                            }
                        />
                    </Col>
                )}

                <Col xs={24} md={24}>
                    <Form
                        onFinish={handleOk}
                        onFinishFailed={handleError}
                        layout="vertical"
                        form={form}
                        labelCol={{ span: 20 }}
                        initialValues={{
                            payment_location_id: payments.location.id,
                            organization_name: organization.name,
                            signer_name: contract.signer_name,
                            organization_address: contract.organization_address || organization.address,
                            fee_to_org: boolToInt(payments.fee_to_org),
                            ks_fee_fixed: intToFloat(payments.ks_fee.fixed),
                            ks_fee_percent: intToFloat(payments.ks_fee.percent),
                            sp_fee_to_org: boolToInt(payments.sp_fee_to_org),
                            ks_sp_fee_fixed: intToFloat(payments.ks_fee.sp_fixed),
                            ks_sp_fee_percent: intToFloat(payments.ks_fee.sp_percent),
                            providers: payments?.providers.map((provider) => {
                                return {
                                    slug: provider.slug,
                                    active: provider.active,
                                    expiration_span: provider.expiration_span,
                                };
                            }),
                        }}
                        autoComplete="off"
                    >
                        <Row className="gx-row-form-child">
                            <Col xs={24} md={12}>
                                <Card className="gx-card" title={<IntlMessages id="common.signer_organization_data" />}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.payment_location',
                                        })}
                                        name="payment_location_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <Select disabled={true} className="gx-field-read-only">
                                            {(paymentLocations || []).map((pl, key) => {
                                                return (
                                                    <Select.Option key={key} value={pl.id}>
                                                        {pl.name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.organization_name',
                                        })}
                                        name="organization_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <Input disabled={!canUpdateContractFields} className="gx-field-read-only" />
                                    </Form.Item>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.administrator_name',
                                        })}
                                        name="signer_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <Input disabled={!canUpdateContractFields} className="gx-field-read-only" />
                                    </Form.Item>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.organization_address',
                                        })}
                                        name="organization_address"
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <Input disabled={!canUpdateContractFields} className="gx-field-read-only" />
                                    </Form.Item>
                                </Card>

                                <Card
                                    title={
                                        <span>
                                            <IntlMessages id="common.payment_methods" />
                                            &nbsp;
                                            <Tooltip title={<IntlMessages id="common.payment_methods_tip" />}>
                                                <InfoCircleOutlined />
                                            </Tooltip>
                                        </span>
                                    }
                                    className="gx-card"
                                >
                                    {payments.providers.map((paymentMethod, index) => {
                                        //Aggiungere controllo su c/c

                                        return <PaymentProvider location={payments.location} provider={paymentMethod} index={index} key={index} organization={currentOrganization} />;
                                    })}
                                </Card>
                            </Col>
                            <Col xs={24} md={12}>
                                <Card className="gx-card" title={<IntlMessages id="common.fees" />}>
                                    <Typography.Paragraph className="gx-text-uppercase">
                                        <IntlMessages id="common.service_fees_by_athlete" />
                                    </Typography.Paragraph>

                                    <Form.Item name="fee_to_org" label={<IntlMessages id="common.fee" />}>
                                        <Select disabled={!canUpdateContractFields} className="gx-field-read-only">
                                            <Select.Option value={0}>
                                                <IntlMessages id="common.fee_to_athlete" />
                                            </Select.Option>
                                            <Select.Option value={1}>
                                                <IntlMessages id="common.fee_to_org" />
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="ks_fee_fixed" label={<IntlMessages id="common.fixed" />}>
                                        <InputNumber
                                            className="gx-field-read-only"
                                            disabled={!isSuperAdmin(me)}
                                            addonAfter={event.payments.location.currency.symbol}
                                            decimalSeparator="."
                                            precision={2}
                                        />
                                    </Form.Item>

                                    <Form.Item name="ks_fee_percent" label={<IntlMessages id="common.percent" />}>
                                        <InputNumber
                                            className="gx-field-read-only"
                                            disabled={!isSuperAdmin(me)}
                                            addonAfter="%"
                                            decimalSeparator="."
                                            precision={2}
                                        />
                                    </Form.Item>

                                    {payments.ks_fee.min > 0 && (
                                        <p className="gx-mt-4">
                                            <span className="gx-text-danger gx-fs-lg gx-pr-2">*</span>
                                            <IntlMessages
                                                id="common.min_fee_advice"
                                                values={{ value: formatCurrency(payments.ks_fee.min / 100, 2, event.payments.location.currency.iso) }}
                                            />
                                        </p>
                                    )}

                                    <Divider />

                                    <Typography.Paragraph className="gx-text-uppercase">
                                        <IntlMessages id="common.service_fees_by_athlete_selling_point" />
                                    </Typography.Paragraph>

                                    <Form.Item name="sp_fee_to_org" label={<IntlMessages id="common.fee" />}>
                                        <Select disabled={!canUpdateContractFields} className="gx-field-read-only">
                                            <Select.Option value={0}>
                                                <IntlMessages id="common.fee_to_athlete" />
                                            </Select.Option>
                                            <Select.Option value={1}>
                                                <IntlMessages id="common.fee_to_org" />
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="ks_sp_fee_fixed" label={<IntlMessages id="common.fixed" />}>
                                        <InputNumber
                                            className="gx-field-read-only"
                                            disabled={!isSuperAdmin(me)}
                                            addonAfter={event.payments.location.currency.symbol}
                                            decimalSeparator="."
                                            precision={2}
                                        />
                                    </Form.Item>

                                    <Form.Item name="ks_sp_fee_percent" label={<IntlMessages id="common.percent" />}>
                                        <InputNumber
                                            className="gx-field-read-only"
                                            disabled={!isSuperAdmin(me)}
                                            addonAfter="%"
                                            decimalSeparator="."
                                            precision={2}
                                        />
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

const EventSubscriptions = ({ match }) => {
    const { params } = match;

    const intl = useIntl();
    const { data, loading, error, refetch } = useQuery(EVENT_CONTRACT, { variables: { id: params.id }, fetchPolicy: 'no-cache' });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    return (
        <>
            <Row>
                <Col md={24}>
                    <BreadcrumbBuilder className="gx-pb-3" items={[{ text: data?.event.name }, { text: intl.formatMessage({ id: 'common.subscriptions' }) }]} />
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    {!loading ? (
                        <div>
                            <EventSubscriptionsForm
                                loading={loading}
                                paymentLocations={data?.paymentLocations}
                                event={data?.event}
                                me={data?.me}
                                currentOrganization={data?.currentOrganization}
                                intl={intl}
                                refetch={refetch}
                            />
                        </div>
                    ) : (
                        <Skeleton />
                    )}
                </Col>
            </Row>
            <DevButton path={__filename} />
        </>
    );
};

export default EventSubscriptions;
